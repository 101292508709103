.tiles {
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
  }
}

// on tablet
@media (min-width: 769px) and (max-width: 1024px) {
  .tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    & > div {
      width: calc(50% - 0.5rem);
      // remove flex-grow and flex-basis
      flex-grow: 0;
      flex-basis: auto;
    }
  }
}

// on mobile
@media (max-width: 768px) {
  .tiles {
    flex-direction: column;
    gap: 1rem;
  }
}
